@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

.app {
  font-family: 'Montserrat',
    sans-serif;
  text-align: center;
  padding: 20px;
  background-color: rgba(201, 178, 178, 0.2);
}

.app h1 {
  font-family: 'Roboto Slab', serif;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color: rgba(201, 180, 180, 0.4);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 10px;
}

.logo {
  width: 200px;
}

.label {
  font-weight: 300;
  font-size: 12px;
}

.select {
  border: none;
  font-size: 12px;
  background: transparent;
}

.drop-list {
  border: none;
  background: transparent;
}

.meals-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.meal-item {
  margin: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 5px;
  width: 250px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: box-shadow 1s ease;
}

.meal-item:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.meal-item img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  cursor: pointer;
}

.meal-item h2 {
  margin-top: 10px;
  font-size: 14px;
}

.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  z-index: 999;
  overflow-y: scroll;
}

.close-button {
  margin: 10px 10px 0 0;
  width: 60px;
  height: 60px;
  background-color: black;
  color: white;
  border: 1px solid black;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.close-button:hover {
  background-color: white;
  color: black;
}

.popup img {
  width: auto;
  max-height: 50%;
  object-fit: cover;
  border-radius: 8px;
  margin: 10px 0 10px 0;
}

.popup p {
  margin: 0 250px;
  text-align: center;
  font-size: 18px;
  line-height: 1.5;
}

.meal-item {
  display: flex;
  flex-direction: column;
  padding: 50px;

}

.comments-section {
  display: flex;
  flex-direction: column;
}

.comment-button {
  font-size: 12px;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  color: black;
  background-color: rgba(201, 180, 180, 0.4);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transition: box-shadow 1s ease;
}

.comment-button:hover {
  color: whitesmoke;
  background-color: black;
  box-shadow: 0 4px 10px rgb(175, 176, 177);
  transform: scale(1.05);
}

.comment-button:disabled {
  cursor: not-allowed;
}

.like-button {
  background: none;
  border: none;
}

.like-icon {
  margin: 0 5px;
  font-size: 20px;
  cursor: pointer;
  animation: heartBeat 1s infinite;
}

.liked {
  margin: 0 5px;
  font-size: 20px;
  color: blue;
  cursor: default;
  animation: none;
}

@keyframes heartBeat {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}
